<template>
	<div>
		<a-spin v-show="!showModal" :spinning="loading">
			<a-form ref="form" :model="formState" name="form" @finish="onSearch">
				<a-row>
					<a-form-item class="ui-form__item" name="organizationId" label="影院组织">
						<a-select placeholder="请选择影院组织" v-model:value="formState.organizationId" style="width: 250px;"
							@change="getAllCinemaList">
							<!-- <a-select-option :value="0">全部</a-select-option> -->
							<a-select-option v-for="item in organizationList" :vlaue="item.id" :key="item.id">
								{{ item.name }}</a-select-option>
						</a-select>
					</a-form-item>

					<a-form-item class="ui-form__item" name="cinemaId" label="所属影院">
						<a-select placeholder="请选择所属影院" v-model:value="formState.cinemaId" style="width: 250px;">
							<a-select-option :value="0">全部</a-select-option>
							<a-select-option v-for="item in cinemaAllList" :vlaue="item.id" :key="item.id">
								{{ item.name }}</a-select-option>
						</a-select>
					</a-form-item>

					<a-form-item class="ui-form__item" name="filmName" label="影片名称">
						<a-input v-model:value="formState.filmName" placeholder="请输入影片名称"></a-input>
					</a-form-item>

					<a-form-item class="ui-form__item" name="buyTicketMode" label="购票模式">
						<a-select placeholder="请选择购票模式" v-model:value="formState.buyTicketMode" style="width: 250px;">
							<a-select-option :value="''">全部</a-select-option>
							<a-select-option :value="1">票务系统出票</a-select-option>
							<a-select-option :value="2">云端系统出票</a-select-option>
						</a-select>
					</a-form-item>

          <a-form-item class="ui-form__item" name="buyTicketMode" label="支付类型">
            <a-select placeholder="请选择支付模式" v-model:value="formState.payTypes" style="width: 250px;">
              <a-select-option :value="''">全部</a-select-option>
              <a-select-option :value="1">微信支付</a-select-option>
              <a-select-option :value="2">年卡</a-select-option>
              <a-select-option :value="3">次卡</a-select-option>
              <a-select-option :value="4">兑换券</a-select-option>
              <a-select-option :value="5">线上会员卡</a-select-option>
              <a-select-option :value="6">抖音券</a-select-option>
              <a-select-option :value="7">影城会员卡</a-select-option>
              <a-select-option :value="8">第三方线下支付</a-select-option>
              <a-select-option :value="9">现金支付</a-select-option>
            </a-select>
          </a-form-item>

          <a-form-item class="ui-form__item" name="isDisabled" label="配置类型">
            <a-select placeholder="请选择配置类型" v-model:value="formState.type" style="width: 250px;">
              <a-select-option :value="''">全部</a-select-option>
              <a-select-option :value="1">按影片配置</a-select-option>
              <a-select-option :value="2">按支付方式配置</a-select-option>
            </a-select>
          </a-form-item>

					<a-form-item class="ui-form__item" name="isDisabled" label="状态">
						<a-select placeholder="请选择购票模式" v-model:value="formState.isDisabled" style="width: 250px;">
							<a-select-option :value="''">全部</a-select-option>
							<a-select-option :value="0">启用</a-select-option>
							<a-select-option :value="1">禁用</a-select-option>
						</a-select>
					</a-form-item>
				</a-row>

				<a-row>
					<a-col :span="18">
						<a-button v-permission="['ciname_basis_filmTicket_add']" type="primary" @click="onAdd">新增配置</a-button>
					</a-col>
					<a-col :span="6" style="text-align: right;">
						<a-button style="margin-right: 10px" type="primary" html-type="submit">搜索</a-button>
						<a-button @click="reset">重置</a-button>
					</a-col>
				</a-row>
			</a-form>

			<div style="margin-top: 20px;">
				<a-table rowKey="orderId" :pagination="pagination" :columns="columns" :dataSource="list"
					:scroll="{ x: 1200 }">
					<template #bodyCell="{ column, record }">
            <template v-if="column.dataIndex === 'type'">
              <a-tag :color="['', '#2db333', '#f11c2b'][record.type]">
                {{['无', '按影片配置', '按支付方式配置'][record.type]}}
              </a-tag>
            </template>
            <template v-if="column.dataIndex === 'filmName'">
              <p v-if="record.filmName">{{ record.filmName }}</p>
              <p v-else>-</p>
            </template>
						<template v-if="column.key === 'buyTicketMode'">
							<a-tag :color="['', '#2db7f5', 'cyan'][record.buyTicketMode]">
								{{['无', '票务系统出票', '云端系统出票'][record.buyTicketMode]}}
							</a-tag>
						</template>
            <template v-if="column.dataIndex === 'payTypes'">
              <template v-if="record.type == 2">
                <a-tag v-for="item in record.payTypess" :color="item.color" style="margin-bottom: 10px;">
                  {{ item.title }}
                </a-tag>
              </template>
              <template v-if="record.type == 1">-</template>
            </template>
						<template v-if="column.key === 'time'">
							<div v-if="record.validType === 2">
								<div>{{ transDateTime(record.startTime, 1) }}</div>
								至
								<div> {{ transDateTime(record.endTime, 1) }} </div>
							</div>
							<div v-else>永久有效</div>
						</template>
						<template v-if="column.key === 'updateTime'">
							{{ record.updateTime ? transDateTime(record.updateTime) : transDateTime(record.createTime) }}
						</template>
						<template v-if="column.key === 'isDisabled'">
							{{ ['已启用', '已禁用'][record.isDisabled] }}
						</template>
						<template v-if="column.key === 'action'">
							<a-dropdown :trigger="['click', 'hover']">
								<a-button @click.prevent>操作 <Icon icon="DownOutlined"></Icon>
								</a-button>
								<template #overlay>
									<a-menu>
										<div v-permission="['ciname_basis_filmTicket_update']" @click="onEdit(record)">
											<a-menu-item>
												编辑
											</a-menu-item>
										</div>
										<div v-permission="['ciname_basis_filmTicket_disabled']" @click="onDisabled(record)">
											<a-menu-item>
												{{ record.isDisabled ? '启用' : '禁用' }}
											</a-menu-item>
										</div>
										<div v-permission="['ciname_basis_filmTicket_delete']" @click="onDelete(record)">
											<a-menu-item>
												删除
											</a-menu-item>
										</div>
									</a-menu>
								</template>
							</a-dropdown>
						</template>
					</template>
				</a-table>
			</div>
		</a-spin>
		<temp v-if="showModal" :isEdit="isEdit" :isSee="isSee" :id="id" @back="onBack"></temp>
	</div>
</template>

<script>
	import {
		Icon
	} from '@/components/icon/icon.js';
	import temp from './temp.vue';
	import {
		getOrganizationList,
		getCinemaList,
		getCinemaFilmModeList,
		changeCinemaFilmMode,
		deleteCinemaFilmMode
	} from '@/service/modules/cinema.js';
	export default {
		components: {
			Icon,
			temp
		},
		data() {
			return {
				loading: false,
				showAll: false,
				organizationList: [],
				cinemaAllList: [],
				time: [],
				formState: {
					buyTicketMode: '',
					isDisabled: '',
					organizationId: 0,
				},
				searchData: {},
				list: [],
				columns: [{
					title: '所属影院',
					dataIndex: 'cinemaName'
				}, {
          title: '配置类型',
          dataIndex: 'type',
          width: 140
        }, {
          title: '优先级',
          dataIndex: 'sort',
          width: 80
        }, {
					title: '影片名称',
					dataIndex: 'filmName'
				}, {
					title: '支付类型',
					dataIndex: 'payTypes',
          width: 140
				}, {
					title: '购票模式',
					key: 'buyTicketMode',
					width: 140
				}, {
					title: '有效期',
					key: 'time'
				}, {
					title: '配置人名称',
					dataIndex: 'username'
				}, {
					title: '配置时间',
					key: 'updateTime'
				}, {
					title: '状态',
					key: 'isDisabled',
					width: 100
				}, {
					title: '操作',
					key: 'action',
					width: 120,
					fixed: 'right'
				}],
				pagination: {
					showSizeChanger: true,
					showQuickJumper: true,
					size: "middle",
					showTotal: (total) => {
						return "共 " + total + " 条";
					},
					total: 0,
					current: 1,
					pageSize: 10,
					onChange: (page) => {
						this.pagination.current = page;
						this.getData();
					},
					onShowSizeChange: (page, pageSize) => {
						this.pagination.current = 1;
						this.pagination.pageSize = pageSize;
						this.getData();
					}
				},
				showModal: false,
				isEdit: false,
				isSee: false,
				id: 0
			}
		},
		created() {
			this.getOrganizationList();
			this.onSearch();
		},
		methods: {
			onBack(isRef) {
				this.showModal = false;
				if (isRef) {
					this.getData();
				}
			},
			onSearch() {
				this.pagination.current = 1;
				// this.pagination.pageSize = 10;
				this.searchData = JSON.parse(JSON.stringify(this.formState));
				this.searchData.organizationId = this.searchData.organizationId ? this.searchData.organizationId : undefined;
				this.searchData.cinemaId = this.searchData.cinemaId ? this.searchData.cinemaId : undefined;
				this.searchData.buyTicketMode = this.searchData.buyTicketMode ? this.searchData.buyTicketMode : undefined;
				this.searchData.isDisabled = this.searchData.isDisabled !== '' ? this.searchData.isDisabled : undefined;
				this.getData();
			},
			reset() {
				this.$refs.form.resetFields();
				this.onSearch();
			},
			onSubmit() {
				this.$refs.addForm.validateFields().then(async () => {
					this.loading = true;
					try {
						let postData = {
							...this.modelRef
						}
						let ret = await updateFilmMode(postData);
						this.loading = false;
						if (ret.code === 200) {
							this.$message.success('操作成功');
							this.$refs.addForm.resetFields();
							this.showModal = false;
							this.modelRef = {};
							this.getData();
						}
					} catch (e) {
						this.loading = false;
					}
				})
			},
			onCancel() {
				this.$refs.addForm.resetFields();
				this.modelRef = {};
				this.showModal = false;
			},
			async getData() {
				this.loading = true;
				try {
					let ret = await getCinemaFilmModeList({
						page: this.pagination.current,
						pageSize: this.pagination.pageSize,
						...this.searchData
					})
					this.loading = false;
					if (ret.code === 200) {
						this.pagination.total = ret.data.totalCount;
						this.list = ret.data.list.map(item=>{
              if (item.type == 2) {
                item.payTypess = item.payTypes.split(',').map(pitem=>{
                  return this.transPayData(pitem);
                });
              }
              return item;
            });
					}
				} catch (e) {
					this.loading = false;
				}
			},
      transPayData(type) {
        const payType = [{
          value: 1,
          title: '微信支付',
          color: '#FF6347'
        },{
          value: 2,
          title: '年卡',
          color: '#32CD32'
        },{
          value: 3,
          title: '次卡',
          color: '#4169E1'
        },{
          value: 4,
          title: '兑换券',
          color: '#FFD700'
        },{
          value: 5,
          title: '线上会员卡',
          color: '#8B4513'
        },{
          value: 6,
          title: '抖音券',
          color: '#6A5ACD'
        },{
          value: 7,
          title: '影城会员卡',
          color: '#FF4500'
        },{
          value: 8,
          title: '第三方线下支付',
          color: '#9400D3'
        },{
          value: 9,
          title: '现金支付',
          color: '#2E8B57'
        }];
        return payType.find(({ value }) => value == type);
      },
			async getOrganizationList() {
				let ret = await getOrganizationList({
					page: 1,
					pageSize: 999999
				});
				if (ret.code === 200) {
					this.organizationList = ret.data.list;
					if (this.organizationList.length) {
						this.formState.organizationId = this.organizationList[0].id;
						this.getAllCinemaList(this.formState.organizationId);
					}
				}
			},
			async getAllCinemaList(organizationId) {
				this.cinemaAllList = [];
				this.formState.cinemaId = 0;
				let ret = await getCinemaList({
					page: 1,
					pageSize: 999999,
					organizationId: organizationId ? organizationId : undefined
				})
				if (ret.code === 200) {
					this.cinemaAllList = ret.data.list;
				}
			},
			onDisabled(record) {
				this.$confirm({
					title: '提示',
					content: `确定${ record.isDisabled ? '启用' : '禁用' }该配置吗？`,
					onOk: async () => {
						this.loading = true;
						let ret = await changeCinemaFilmMode({
							id: record.id,
							isDisabled: record.isDisabled ? 0 : 1
						});
						this.loading = false;
						if (ret.code === 200) {
							this.$message.success(`${ record.isDisabled ? '启用' : '禁用' }成功！`)
							this.getData();
						}
					}
				})
			},
			onDelete(record) {
				this.$confirm({
					title: '提示',
					content: '确定删除该配置吗？',
					onOk: async()=> {
						this.loading = true;
						let ret = await deleteCinemaFilmMode({
							id: record.id
						})
						this.loading = false;
						if (ret.code === 200) {
							this.$message.success('删除成功！')
							this.getData();
						}
					}
				})
			},
			onAdd() {
				this.id = 0;
				this.isEdit = false;
				this.isSee = false;
				this.showModal = true;
			},
			onEdit(item) {
				this.id = item.id;
				this.isEdit = true;
				this.isSee = false;
				this.editData = item;
				this.showModal = true;
			},
			beforeUpload(file) {
				const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
				if (!isJpgOrPng) {
					this.$message.error('上传文件格式不正确');
				}
				const isLt1M = file.size / 1024 / 1024 < 2;
				if (!isLt1M) {
					this.$message.error('上传文件太大了');
				}
				return isJpgOrPng && isLt1M;
			},
			handleChange(info) {
				if (!info.file.status) {
					this.fileList.splice(this.fileList.length - 1, 1);
				}
				if (info.file.status === 'done') {
					this.modelRef.imgUrl = '';
					const $fileList = [...info.fileList];
					$fileList.map(file => {
						if (file.response) {
							this.modelRef.imgUrl = file.response.data.imgUrl;
							file.url = file.response.data.imgUrl;
						}
						return file;
					});
				}
			},
		}
	}
</script>

<style scoped>
	.ui-form__item {
		margin-right: 30px;
	}
</style>
