<template>
	<div>
		<Header :title="isEdit ? '编辑购票模式配置' : '新增购票模式配置'" @back="onBack(false)"></Header>
		<a-spin :spinning="loading">
			<a-form style="margin-top: 20px;" ref="form" name="form" :model="modelRef" scrollToFirstError
				:label-col="{ span: 4 }" :wrapper-col="{ span: 14 }" @finish="onSubmit">

				<a-form-item class="ui-form__item" name="organizationId" label="影院组织" :rules="[{ required: true, message: '必选项不允许为空' }]">
					<a-select :disabled="isSee || isEdit" placeholder="请选择" v-model:value="modelRef.organizationId" @change="(id) => getAllCinemaList(id)">
						<a-select-option v-for="item in organizationList" :vlaue="item.id" :key="item.id"> {{ item.name }}</a-select-option>
					</a-select>
				</a-form-item>

				<a-form-item class="ui-form__item" name="cinemaId" label="所属影院" :rules="[{ required: true, message: '必选项不允许为空' }]">
					<a-select :disabled="isSee || isEdit" placeholder="请选择" v-model:value="modelRef.cinemaId" @change="onCinemaChange">
						<a-select-option v-for="item in cinemaList" :vlaue="item.id" :key="item.id"> {{ item.name }}</a-select-option>
					</a-select>
				</a-form-item>

				<!-- <a-form-item label="标题" name="name" :rules="[{ required: true, message: '必填项不允许为空' }]">
					<a-input v-model:value="modelRef.name" placeholder="请输入" />
				</a-form-item> -->

        <a-form-item label="配置类型" name="type" :rules="[{required: true, message: '必选项不允许为空'}]">
          <a-radio-group :disabled="isSee || isEdit" v-model:value="modelRef.type" button-style="solid">
            <a-radio-button :value="1">按影片配置</a-radio-button>
            <a-radio-button :value="2">按支付方式配置</a-radio-button>
          </a-radio-group>
        </a-form-item>

				<a-form-item label="购票模式" name="buyTicketMode" :rules="[{required: true, message: '必选项不允许为空'}]">
					<a-radio-group :disabled="isSee" v-model:value="modelRef.buyTicketMode" button-style="solid">
						<a-radio-button :value="1">票务系统出票</a-radio-button>
						<a-radio-button :value="2">云端系统出票</a-radio-button>
					</a-radio-group>
				</a-form-item>

				<a-form-item label="影片" name="standardCode" :rules="[{ required: true, message: '必选项不允许为空' }]" v-if="modelRef.type == 1">
					<a-form-item-rest>
						<div v-if="modelRef.cinemaId">
							<movie :id="modelRef.cinemaId" :disabled="isSee || isEdit" v-model:value="modelRef.standardCode" :selectItem="movieList" type="radio" @change="(dataList) => { movieList = dataList; }"></movie>
						</div>
						<a-button v-else disabled>请选择所属影院</a-button>
					</a-form-item-rest>
					<div v-if="movieList.length" style="margin: 10px 0;">
						<a-table rowKey="standardCode" :columns="movieColumns" :dataSource="movieList" size="small" :pagination="false">
							<template #bodyCell="{ column, record, index }">
								<template v-if="column.key === 'action'">
									<a-button :disabled="isSee" type="link" @click="onDeleteMovie(record, index)">删除</a-button>
								</template>
							</template>
						</a-table>
					</div>
				</a-form-item>

        <a-form-item label="支付类型" name="payTypes" :rules="[{required: true, message: '必选项不允许为空'}]" v-if="modelRef.type == 2">
          <a-select v-model:value="modelRef.payTypes" size="middle" mode="tags">
            <a-select-option :value="1">微信支付</a-select-option>
            <a-select-option :value="2">年卡</a-select-option>
            <a-select-option :value="3">次卡</a-select-option>
            <a-select-option :value="4">兑换券</a-select-option>
            <a-select-option :value="5">线上会员卡</a-select-option>
            <a-select-option :value="6">抖音券</a-select-option>
            <a-select-option :value="7">影城会员卡</a-select-option>
            <a-select-option :value="8">第三方线下支付</a-select-option>
            <a-select-option :value="9">现金支付</a-select-option>
          </a-select>
        </a-form-item>

				<a-form-item label="有效期" name="validType" :rules="[{required: true, message: '必填项不允许为空'}]">
					<a-radio-group :disabled="isSee" v-model:value="modelRef.validType" button-style="solid">
						<a-radio-button :value="1">永久有效</a-radio-button>
						<a-radio-button :value="2">指定日期</a-radio-button>
					</a-radio-group>
				</a-form-item>

				<a-form-item v-if="modelRef.validType === 2" label="指定日期" name="time" :rules="[{required: true, message:'必填项不允许为空'}]">
					<a-range-picker :disabled="isSee" v-model:value="modelRef.time"/>
				</a-form-item>

        <a-form-item label="优先级" name="sort">
          <a-input :disabled="isSee" v-model:value="modelRef.sort" placeholder="不填默认为0"></a-input>
        </a-form-item>

				<a-form-item label="是否启用" name="isDisabled" :rules="[{ required: true, message: '必填项不允许为空' }]">
					<a-radio-group :disabled="isSee" v-model:value="modelRef.isDisabled">
						<a-radio :value="0">启用</a-radio>
						<a-radio :value="1">禁用</a-radio>
					</a-radio-group>
				</a-form-item>

				<a-form-item>
					<div style="text-align: center;">
						<a-button type="primary" v-if="!isSee" html-type="submit">提交</a-button>
						<a-button style="margin-left: 20px;" @click="onBack(false)">返回</a-button>
					</div>
				</a-form-item>
			</a-form>
		</a-spin>
	</div>
</template>

<script>
	import Header from '@/components/header/header.vue';
	import movie from './components/movie.vue';
	import { getOrganizationList, getCinemaList, getCinemaFilmModeDetail, saveCinemaFilmMode, updateCinemaFilmMode } from '@/service/modules/cinema.js';
	export default {
		components: {Header, movie},
		props: {
			isEdit: {
				type: Boolean,
				default: false
			},
			isSee: {
				type: Boolean,
				default: false
			},
			id: {
				type: Number,
				default: 0
			}
		},
		data() {
			return {
				loading: false,
				modelRef: {
					isDisabled: 0,
					standardCode: [],
					// buyTicketMode: 1,
					validType: 1,
					time: [],
          type: 0,
          payTypes: [],
          sort: '',
				},
				organizationList: [],
				cinemaList: [],
				movieList: [],
				movieColumns: [{
					title: '唯一编码',
					dataIndex: 'standardCode'
				}, {
					title: '影片名称',
					dataIndex: 'name'
				},
				// {
				// 	title: '语言',
				// 	dataIndex: 'language',
				// 	width: 80,
				// 	align: 'center'
				// },
				// {
				// 	title: '操作',
				// 	key: 'action'
				// },
				],
			}
		},
		created() {
			this.getOrganizationList();
			if (this.id) {
				this.getDetail();
			}
		},
		methods: {
			onBack(isRef) {
				this.$emit('back', isRef);
			},
			async onSubmit() {
				this.loading = true;
				let postData = JSON.parse(JSON.stringify(this.modelRef));
				if (postData.validType === 2 && postData.time) {
					postData.startTime = this.moment(postData.time[0]).startOf('day').unix();
					postData.endTime = this.moment(postData.time[1]).endOf('day').unix();
				}
				delete postData.time
        if (postData.type == 1) {
          postData.standardCode = postData.standardCode[0];
          postData.payTypes = '';
        } else if (postData.type == 2) {
          postData.payTypes = postData.payTypes.join(',');
          postData.standardCode = '';
          postData.filmName = '';
        }
				let ret;
				if (this.isEdit) {
					ret = await updateCinemaFilmMode(postData);
				} else {
					ret = await saveCinemaFilmMode(postData);
				}
				this.loading = false;
				if (ret.code === 200) {
					if (this.isEdit) {
						this.$message.success('编辑成功！');
					} else {
						this.$message.success('新增成功！');
					}
					this.onBack(true);
				}
			},
			async getDetail() {
				this.loading = true;
				try {
					let ret = await getCinemaFilmModeDetail({
						id: this.id
					});
					this.loading = false;
					if (ret.code === 200) {
						ret.data.standardCode = [ret.data.standardCode];
						if (ret.data.validType === 2) {
							ret.data.time = [this.moment(ret.data.startTime * 1000), this.moment(ret.data.endTime * 1000)];
						} else {
							ret.data.time = [];
						}
            if (ret.data.payTypes) ret.data.payTypes = ret.data.payTypes.split(',').map(item=>{return parseInt(item)});
						this.movieList = [ret.data.cinemaFilm];
						delete ret.data.cinemaFilm;
						delete ret.data.startTime;
						delete ret.data.endTime;
						this.getAllCinemaList(ret.data.organizationId);
						this.modelRef = ret.data;
					}
				} catch(e) {
					this.loading = false;
				}
			},
			async getOrganizationList() {
				let ret = await getOrganizationList({
					page: 1,
					pageSize: 999999
				});
				if (ret.code === 200) {
					this.organizationList = ret.data.list;
				}
			},
			async getAllCinemaList(organizationId) {
				this.cinemaAllList = [];
				this.modelRef.cinemaId = undefined;
				let ret = await getCinemaList({
					page: 1,
					pageSize: 999999,
					organizationId: organizationId ? organizationId : undefined
				})
				if (ret.code === 200) {
					this.cinemaList = ret.data.list;
				}
			},
			onCinemaChange() {
				this.movieList = [];
				this.modelRef.standardCode = [];
			},
			onDeleteMovie(item, index) {
				let i = this.modelRef.standardCode.indexOf(item.id);
				this.modelRef.standardCode.splice(i, 1);
				this.movieList.splice(index, 1);
			},
		}
	}
</script>

<style>
</style>
