<template>
	<span>
		<a-spin :spinning="loading">
			<span @click="onShowModal">
				<slot>
					<a-button :disabled="disabled" type="primary">选择影片</a-button>
					<span> 已选{{selectedRowKeys.length}}部影片</span>
				</slot>
			</span>
			
			<a-modal v-model:visible="showSnackModal" title="选择影片" destroyOnClose width="880px" @cancel="onCancel" @ok="onOK">
				<a-spin :spinning="loading">
					<a-form ref="form" name="form" :model="formState" @finish="onSearch">
						<a-row>
							<a-col :span="18">
								<a-row>
									<a-form-item name="name" label="影片名称" style="margin-right: 30px;">
										<a-input v-model:value="formState.name" placeholder="请输入影片名称"></a-input>
									</a-form-item>
								</a-row>
							</a-col>
							
							<a-col :span="6" style="text-align: right;">
								<a-button type="primary" html-type="submit">搜索</a-button>
								<a-button style="margin-left: 20px;" @click="reset">重置</a-button>
							</a-col>
						</a-row>
					
						<a-row>
							<a-col :span="18"></a-col>
							
						</a-row>
					</a-form>
					<div style="max-height: 590px;overflow-y: auto;">
						<a-table rowKey="standardCode" :dataSource="dataList" :pagination="pagination"
							:columns="columns"
							:rowSelection="{ type: type, selectedRowKeys: selectedRowKeys, onSelect: onSelectChange, onSelectAll: onSelectAll, onChange: onChange }"
							bordered size="middle"
						>
							<template #bodyCell="{column, record}"></template>
						</a-table>
					</div>
				</a-spin>
			</a-modal>
		</a-spin>
	</span>
</template>

<script>
	import { getOnlyFilmList } from '@/service/modules/cinema.js';
	export default {
		model: {
			prop: 'value'
		},
		props: {
			value: {
				type: Array,
				default: () => {
					return []
				}
			},
			selectItem: {
				type: Array,
				default: () => {
				    return []
				}
			},
			id: {	//影院ID
				type: Number,
				default: 0
			},
			type: {
				type: String,
				default: 'checkbox'
			},
			searchMap: { // 外部传入搜索数据
				type: Object,
				default: () => {}
			},
			disabled: {
				type: Boolean,
				default: false
			}
		},
		data() {
			return {
				loading: false,
				showSnackModal: false,
				classifyList: [],
				formState: {
					type: '',
					isDisabled: '',
					isShelf: '',
				},
				searchData: {},
				selectedRowKeys: [],
				selectedListData:[],
				dataList: [],
				columns: [{
					title: '唯一编码',
					dataIndex: 'standardCode'
				}, {
					title: '名称',
					dataIndex: 'shortName'
				}, 
				// {
				// 	title: '语言',
				// 	dataIndex: 'language',
				// 	width: 80,
				// 	align: 'center'
				// },
				],
				pagination: {
					showSizeChanger: true,
					showQuickJumper: true,
					size: "middle",
					showTotal: (total) => {
						return "共 " + total + " 条";
					},
					total: 0,
					current: 1,
					pageSize: 10,
					onChange: (page) => {
						this.pagination.current = page;
						this.getData();
					},
					onShowSizeChange: (page, pageSize) => {
						this.pagination.current = 1;
						this.pagination.pageSize = pageSize;
						this.getData();
					}
				},
			}
		},
		watch: {
			selectItem: {
				handler(newVal) {
					this.selectedListData = JSON.parse(JSON.stringify(newVal));
				},
				deep: true,
				immediate: true
			},
			value: {
				handler(newVal) {
					this.selectedRowKeys = JSON.parse(JSON.stringify(newVal));
				},
				deep: true,
				immediate: true
			}
		},
		created(){
		},
		methods: {
			onShowModal() {
				if (this.disabled) return;
				this.getData();
				this.showSnackModal = true;
			},
			onSearch() {
				this.pagination.current = 1;
				this.searchData = JSON.parse(JSON.stringify(this.formState));
				this.getData();
			},
			reset() {
				this.$refs.form.resetFields();
				this.onSearch();
			},
			async getData() {
				this.searchData.type = 1;
				this.loading = true;
				try {
					let ret = await getOnlyFilmList({
						page: this.pagination.current,
						pageSize: this.pagination.pageSize,
						cinemaId: this.id,
						...this.searchData,
					})
					this.loading = false;
					if (ret.code === 200) {
						this.dataList = ret.data.list;
						this.pagination.total = ret.data.totalCount;
					}
				} catch(e) {
					//console.log(e);
					this.loading = false;
				}
			},
			onSelectChange(record, selected) {
				if (this.type === 'checkbox') {
					if (selected) {
						this.selectedListData.push(JSON.parse(JSON.stringify(record)));
						this.selectedRowKeys.push(record.standardCode);
					} else {
						this.selectedRowKeys.splice(this.selectedRowKeys.indexOf(record.standardCode), 1);
						this.selectedListData = this.selectedListData.filter(item => {
							return item.standardCode !== record.standardCode;
						});
					}
				} else {
					if (selected) {
						this.selectedListData = [JSON.parse(JSON.stringify(record))];
						this.selectedRowKeys = [record.standardCode];
					} else {
						this.selectedListData = [JSON.parse(JSON.stringify(record))];
						this.selectedRowKeys = [];
					}
				}
			},
			onSelectAll(selected) {
				if (selected) {
					this.dataList.forEach(item => {
						if (this.selectedRowKeys.indexOf(item.standardCode) === -1) {
							this.selectedListData.push(JSON.parse(JSON.stringify(item)));
							this.selectedRowKeys.push(item.standardCode);
						}
					})
				} else {
					this.dataList.forEach(item => {
						if (this.selectedRowKeys.indexOf(item.standardCode) !== -1) {
							this.selectedListData.splice(this.selectedRowKeys.indexOf(item.standardCode), 1);
							this.selectedRowKeys.splice(this.selectedRowKeys.indexOf(item.standardCode), 1);
						}
					})
				}
			},
			onChange(key) {
				
			},
			onCancel() {
			    this.showSnackModal = false;
			    this.selectedRowKeys = JSON.parse(JSON.stringify(this.value));
				this.selectedListData = JSON.parse(JSON.stringify(this.selectItem));
			},
			onOK() {
				this.$emit('update:value', JSON.parse(JSON.stringify(this.selectedRowKeys)));
				this.$emit('change', JSON.parse(JSON.stringify(this.selectedListData)));
				this.showSnackModal = false;
			}
		}
	}
</script>

<style>
</style>
